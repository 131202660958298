import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
// import MUIRichTextEditor from 'mui-rte';
import {
  MaterialSlate,
  MaterialEditable,
  createMaterialEditor,
  Toolbar as TBar,
  BoldButton,
  ItalicButton,
  StrikethroughButton,
  LinkButton,
  NumberedListButton,
  BulletedListButton,
  ToolbarButton,
} from '@unicef/material-slate'
import markdown from '@wcj/markdown-to-html';
import { convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { draftToMarkdown } from 'markdown-draft-js';
import { serializeNode } from '../../../utils/serializeNode';
import { Typography } from '@mui/material';
const PREFIX = 'RichTextInputField';

const classes = {
  root: `${PREFIX}-root`,
  subroot: `${PREFIX}-subroot`,
  label: `${PREFIX}-label`,
  sublabel: `${PREFIX}-sublabel`,
  base: `${PREFIX}-base`,
  richInput: `${PREFIX}-richInput`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.root}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.subroot}`]: {
    //border: 'solid 2px green',
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  [`& .${classes.label}`]: {
    flexBasis: '20%',
    // minWidth: '15%',
    textAlign: 'right',
    marginRight: '10px'
  },
  [`& .${classes.sublabel}`]: {
    flexBasis: '100%',
    // minWidth: '15%',
    // textAlign: 'right',
    marginLeft: ({ label }) => label ? 'calc(20% + 16px)' : '10px'
  },
  [`& .${classes.base}`]: {
    flexBasis: '80%',
  },
  [`& .${classes.richInput}`]: {
    border: '1px solid #bdbdbd',
    borderRadius: '4px'
  },
});

const emptyDefaultVal = [
  {
    type: 'paragraph',
    children: [
      { text: '' },
    ],
  }
];

const RichTextInputField = React.memo(({ value, label, disabled, placeholder, maxLength, onChange, invalid, large, endComponent, sublabel, html }) => {

  const [defaultValue, setDefaultValue] = useState()

  const loadedRef = useRef(false)
  const valueRef = useRef()
  const editorStateRef = useRef()
  // Holds the value of the editor
  const [editorStateVal, setEditorStateVal] = useState(emptyDefaultVal)
  const [editorKey, setEditorKey] = useState(0); // Track key for re-render


  // An instance of material editor. It is an slate editor with a few more functions
  const editor = useMemo(() => createMaterialEditor(), [])

  useEffect(() => {
    const point = { path: [0, 0], offset: 0 }
    if (editor) editor.selection = { anchor: point, focus: point }
  }, [editor])

  useEffect(() => {
    if (value?.template) {
      const newValue = { ...value }
      delete newValue.template
      let arrVal = Object.values(newValue);
      setDefaultValue(arrVal)
      editorStateRef.current = arrVal;
    }
  }, [value])

  const handleChange = useCallback((editorState) => {
    if (html) {
      const markdownText = editorState.map(serializeNode).join('\n');
      const htmlText = markdown(markdownText)
      valueRef.current = htmlText;
    } else {
      valueRef.current = editorState
    }
    onChange(null, valueRef.current)
    setEditorStateVal(editorState)
    setEditorKey(prev => prev + 1)
    editorStateRef.current = valueRef.current
  }, [html, onChange]);

  useEffect(() => {
    if (!!defaultValue && loadedRef.current) {
      handleChange(editorStateRef.current)
    }
    loadedRef.current = true
  }, [defaultValue, handleChange])



  return (
    (<Root>
      <div className={classes.root}>
        {label && <Typography className={classes.label}>{label}</Typography>}
        <div className={classes.base}>
          <MaterialSlate key={editorKey} editor={editor} value={editorStateVal} onChange={handleChange}>
            <TBar style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <BoldButton />
              <ItalicButton />
              <StrikethroughButton />
              {/* <LinkButton /> */}
              <NumberedListButton />
              <BulletedListButton />
            </TBar>
            <MaterialEditable />
          </MaterialSlate>
          {/* <MUIRichTextEditor
            disabled={true}
            // ref={editorRef}
            label={placeholder}
            className={classes.richInput}
            onChange={handleChange}
            onSave={console.log}
            defaultValue={defaultValue}
            toolbarButtonSize="small"
            inlineToolbar={true}
            controls={["title", "bold", "italic", "underline", "strikethrough", "clear", "link", "numberList", "bulletList"]}
            inlineToolbarControls={["bold", "italic", "underline", "strikethrough", "link", "clear"]}
          /> */}
        </div>
      </div>
      {sublabel && <div className={classes.subroot}>
        <Typography variant="caption" className={classes.sublabel}>{typeof sublabel === 'function' ? sublabel() : sublabel}</Typography>
      </div>}
    </Root>)
  );
})

// RichTextInputField.defaultProps = {
//   label: 'Text'
// }

export default RichTextInputField;
