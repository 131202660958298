

export const serializeNode = (node) => {
    if (!!node.text) {
        let text = node.text;
        if (node.bold) {
            text = `**${text}**`;
        }
        if (node.italic) {
            text = `_${text}_`;
        }
        if (node.strikethrough) {
            text = `~~${text}~~`;
        }
        if (node.code) {
            text = `\`${text}\``
        }
        return text;
    } else {
        switch (node.type) {
            case 'bulleted-list':
                return node.children
                    .map((child) => `- ${serializeNode(child)}`)
                    .join('\n');
            case 'numbered-list':
                return node.children
                    .map((child, index) => `${index + 1}. ${serializeNode(child)}`)
                    .join('\n');
            case 'list-item':
                return node.children.map(serializeNode).join('');
            default:
                return node.children?.map(serializeNode).join('')
        }
    }
}