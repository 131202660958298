import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import ReactPlayerLoader from './ReactPlayerLoader'
import 'videojs-overlay';
import { useEventListener } from '../hooks/hooks'
import { IconButton } from '@mui/material';
import VolumeOffIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

const PREFIX = 'BrightcoveVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  playButton: `${PREFIX}-playButton`,
  muteButton: `${PREFIX}-muteButton`,
  customOverlay: `${PREFIX}-customOverlay`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({wmOpacity}) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.playButton}`]: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    zIndex: 1004
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    color: 'white',
    zIndex: 1200
  },
  [`& .${classes.customOverlay}`]: {
    // color: 'white !important',
    backgroundColor: 'transparent !important',
    height: '100% !important',
    width: '100% !important',
    marginTop: 'auto !important',
    marginBottom: 'auto !important',
    left: '0 !important',
    marginLeft: '0 !important',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    opacity: (wmOpacity && `${wmOpacity / 100} !important`) || '0.15 !important',
    // fontSize: 'calc(9px + (140 - 9) * ((100vw - 150px) / (2400 - 150))) !important',
    fontSize: '1em',
    userSelect: 'none !important',
    MozUserSelect: 'none !important',
    WebkitUserSelect: 'none !important',
    msUserSelect: 'none !important',
    // transform: 'translateY(-50%) rotate(-20.5deg) !important',
    // pointerEvents: 'none !important'
  }
}));

// document.pictureInPictureEnabled = false

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const overlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%' viewBox='0 0 200 100'>    
      <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
              <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
              <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
              <feGaussianBlur in="map" stdDeviation="${Math.floor(Math.random()) / 1000 + 0.15}" />
      </filter>
      <text x="50%" y="50%" transform="rotate(-${Math.atan(height / width) * 180 / Math.PI}, 100, 50)" filter="url(#filter)" textLength="200" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
    </svg>
  </svg>`
}

const tiledOverlay = (content, height, width, seed) => {
  return `<svg style="object-fit: contain" height='100%' width='100%' viewBox='0 0 ${width} ${height}'>
    <svg height='100%' width='100%'>
      <defs>
        <filter id='filter' x='0%' y='0%' width='100%' height='100%'>
          <feTurbulence in="SourceGraphic" baseFrequency="10" octaves="10" type="fractalNoise" seed="${seed}" result="noise" />
          <feDisplacementMap in="SourceGraphic" in2="noise" scale="0.7" xChannelSelector="R" yChannelSelector="R" result="map" />
          <feGaussianBlur in="map" stdDeviation="${height / 2000} ${width / 2000}" />
        </filter>
        <pattern id="p1" height='25%' width='50%' style="font-size: ${height / 22}px" patternUnits="userSpaceOnUse" patternTransform="rotate(-18.43494882292201)">    
          <text x="25%" y="12.5%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="25%" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="0" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
          <text x="50%" y="0" textLength="28%" fill="white" method="stretch" lengthAdjust="spacingAndGlyphs" dominant-baseline="middle" text-anchor="middle">${content}</text>
        </pattern>
      </defs>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#p1)" filter="url(#filter)"></rect>
    </svg>
  </svg>`
}

const BrightcoveVideoPlayer = React.memo(({
  videoRef,
  style,
  fluid,
  fill,
  fullscreen,
  startVideo,
  wm,
  wmOpacity,
  watermarkStyle,
  playerId,
  videoId,
  multiSection,
  videoIndex,
  alreadyStarted,
  sessionComplete,
  sessionStartTime,
  connectionOffset,
  onTimeUpdate,
  videoDuration,
  videoStopTime,
  onStarted,
  onPlay,
  onResume,
  onBuffer,
  onUnload,
  onMetadataLoaded,
  onVideoLoaded,
  onVisiblityChange,
  onFinished,
  onVideoError
}) => {

  // const [resuming, setResuming] = useState(alreadyStarted)
  const [muted, setMuted] = useState(true)
  const [playing, setPlaying] = useState(false)

  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  const indexRef = useRef(videoIndex || 0)
  const loadedRef = useRef()
  const startedRef = useRef()
  startedRef.current = alreadyStarted

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    if (playerRef.current) {
      indexRef.current = videoIndex || 0
      if (!!playerRef.current?.playlist()?.length) playerRef.current.playlist.currentItem(videoIndex || 0)
      if (startVideo) {
        playerRef.current.play()
      } else if (!playerRef.current.paused()) {
        playerRef.current.currentTime(playerRef.current.duration())
        setPlaying(false)
      }
    }
  }, [videoIndex, startVideo])

  useEventListener('blur', onVisiblityChange || visibilityChangeFn, true);
  useEventListener('focus', onVisiblityChange || visibilityChangeFn, true);
  useEventListener(visibilityChange, onVisiblityChange || visibilityChangeFn, true);

  const overlayFn = useMemo(() => {
    return watermarkStyle === 'Tiled' ? tiledOverlay : overlay
  }, [watermarkStyle])

  // const handleResume = useCallback(() => {
  //   if (videoTime && unloadTime) {
  //     playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
  //   } else {
  //     playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
  //   }
  //   playerRef.current.play()
  //   setResuming(false)
  // }, [])

  useEffect(() => {
    const now = Date.now()
    if (sessionComplete || (sessionStartTime && (now - sessionStartTime) >= ((videoStopTime * 1000) || videoDuration))) {
      if (onFinished) {
        onFinished()
      } else if (playerRef.current) {
        playerRef.current.currentTime(playerRef.current.duration())
      }
    }
  }, [sessionComplete, videoDuration, videoStopTime, sessionStartTime, onFinished])

  const afterLoaded = useCallback((player) => {
    playerRef.current = player.ref
    console.log(playerRef.current)
    if (videoRef) videoRef.current = player.ref //to get time data

    playerRef.current.tech_.el_.disablePictureInPicture = true
    playerRef.current.tech_.el_.controls = false
    playerRef.current.tech_.el_.blur();
    playerRef.current.tech_.el_.addEventListener('focus', (e) => {
      e.currentTarget.blur()
      console.log('video focus')
    })

    playerRef.current.el_.blur();
    playerRef.current.el_.addEventListener('focus', (e) => {
      e.currentTarget.blur()
      console.log('brightcove focus')
    })

    playerRef.current.preload('metadata')
    indexRef.current = videoIndex || 0
    // playerRef.current.preload('none')

    playerRef.current.controls(false);
    if (fullscreen) playerRef.current.enterFullWindow();
    playerRef.current.autoplay(false)
    playerRef.current.muted(true)

    //INITAL BLANK OVERLAY
    playerRef.current.overlay({
      content: '',
      class: classes.customOverlay,
      overlays: [{
        align: "center",
        content: "",
        start: 'play',
        end: 'ended'
      }]
    });

    playerRef.current.on('durationchange', (event) => {
      console.log('durationchange event')
    })

    // playerRef.current.on('loadstart', (event) => {
    //   console.log('load start event')
    // })

    playerRef.current.on('play', (event) => {
      console.log('play event')
      if (onStarted) onStarted()
    })

    playerRef.current.on('pause', () => {
      if (!playerRef.current.ended()) {
        playerRef.current.play()
      } else {
        playerRef.current.currentTime(0)
      }
      console.log('pause event')
    })

    playerRef.current.on('ended', () => {
      console.log('end event', indexRef.current)
      if (onFinished) onFinished((indexRef.current + 1) < (playerRef.current.playlist()?.length || 0))
    })

    playerRef.current.on('timeupdate', (e) => {
      playerRef.current.overlay({
        content: '',
        class: classes.customOverlay,
        overlays: [{
          align: "center",
          content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
          start: 'play',
          end: 'ended'
        }]
      });
      if (onTimeUpdate) onTimeUpdate(e)
    })

    playerRef.current.on('seeked', () => {
      console.log('seeked event')
      // if (videoTime && !loadedRef.current) {
      //   console.log('FAST forwarding')
      //   console.log(videoTime, unloadTime, videoTime + (Date.now() - unloadTime) / 1000)
      //   playerRef.current.currentTime(videoTime + (Date.now() - unloadTime) / 1000)
      //   loadedRef.current = true
      // }
    })

    playerRef.current.on('error', (e) => {
      console.log('error event', e)
      onVideoError && onVideoError()
    })

    playerRef.current.on('seeking', () => {
      console.log('seeking event')
    })

    playerRef.current.on('loadedmetadata', () => {
      console.log('loadedmetadata event')
      // GUEST PLAYER
      // if (!alreadyStarted) {
      //   playerRef.current.play()
      // }

      // ADMIN VIEWER
      if (!loadedRef.current && alreadyStarted && !(sessionComplete || ((sessionStartTime && (Date.now() - sessionStartTime) / 1000) >= videoDuration))) {
        if (!!playerRef.current.playlist()?.length) playerRef.current.playlist.currentItem(indexRef.current)
        console.log('WE HAVE STARTED ALREADY SO TRYING TO MOVE US FORWARD NOW...', (Date.now() - sessionStartTime) / 1000)
        playerRef.current.currentTime((Date.now() - sessionStartTime) / 1000)
        playerRef.current.play()
      }
      loadedRef.current = true;
      playerRef.current.overlay({
        content: '',
        class: classes.customOverlay,
        overlays: [{
          align: "center",
          content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
          start: 'play',
          end: 'ended'
        }]
      });
      // setAspectRatio(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
      // if (onMetadataLoaded) onMetadataLoaded(playerRef.current.tech_.el_.videoHeight / playerRef.current.tech_.el_.videoWidth)
    })

    playerRef.current.on('loadeddata', () => {
      console.log('loadeddata event')
      if (onVideoLoaded) onVideoLoaded()
      // setVideoLoaded(true)
    })

    // playerRef.current.on('durationchange', (e) => {
    //   console.log('durationchange event', e)
    // })

    playerRef.current.on('waiting', () => {
      console.log('waiting event')
      if (onBuffer) onBuffer()
    })
  }, [alreadyStarted, fullscreen, onFinished, onStarted, onBuffer, onTimeUpdate, onVideoError, onVideoLoaded, onMetadataLoaded, sessionComplete, videoIndex, videoDuration, videoRef, sessionStartTime, wm, overlayFn])

  const toggleMute = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.muted(!muted)
      setMuted(prev => !prev)
    }
  }, [muted, setMuted])

  const togglePlay = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playing ? playerRef.current.currentTime(playerRef.current.duration()) : playerRef.current.play()
      setPlaying(prev => !prev)
    }
  }, [playing])

  const toggleFullScreen = useCallback((event) => {
    event.currentTarget.blur()
    if (playerRef.current) {
      playerRef.current.requestFullscreen();
    }
  }, [])

  return (
    <Root wmOpacity={wmOpacity}>
      {/* {resuming && <>
        <div className={classes.continueBack}></div>
        <Card className={classes.continue} elevation={12}>
          Please click below to {videoTime && unloadTime ? 'resume' : 'join'} session
        <Button className={classes.margin} color='primary' variant='contained' onClick={handleResume}>{videoTime && unloadTime ? 'Resume' : 'Join'}</Button>
        </Card>
      </>} */}
      <div style={{ position: 'relative' }}>
        <ReactPlayerLoader attrs={{ style }} options={{ fluid, fill }} accountId='6121265898001' playerId={playerId} videoId={!multiSection && videoId} playlistId={multiSection && videoId} onSuccess={afterLoaded} />
        {(!startVideo && !alreadyStarted) ?
          <IconButton className={classes.playButton} color="inherit" onClick={togglePlay}>
            {playing ? <StopIcon /> : <PlayArrowIcon />}
          </IconButton> : <IconButton className={classes.playButton} color="inherit" onClick={toggleFullScreen}>
            {/* {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />} */}
            <FullscreenIcon />
          </IconButton>
        }
        <IconButton className={classes.muteButton} color="inherit" onClick={toggleMute}>
          {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </div>
    </Root>
  );
})

export default BrightcoveVideoPlayer;
